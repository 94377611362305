<template>
    <div class="template-page seminars blue-bg">
        <basic-page :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <div class="paragrafo" v-if="this.pageData.body" v-html="this.pageData.body[0].processed"></div>

            <h3>Filter by</h3>
            
            <div class="filtri">

                <div class="tags-filter">
                    <button type="checkbox" 
                        :class="{'image-tag': true, 'active': checkActive(t)}" 
                        v-for="(t, index) in this.tagsArray" :key="'tag'+index" :value="t" name="filtertag"
                        @click="addFilter">{{ t }}</button>
                </div>
                
                <div class="ricerca-testo">
                    <input v-model="research" placeholder="Search the content...">
                </div>

            </div>
            
            <h2 class="title-section" v-if="this.upcomingSeminarData.length > 0">Upcoming Seminars</h2>
            <router-link :to="seminarLink(seminar)" v-for="(seminar, index) in this.upcomingSeminarData" :key="'upcoming-seminar'+index">
                <upcoming-seminar :data="seminar"></upcoming-seminar>
            </router-link>

            <h2 class="title-section" v-if="this.loadedSeminars.length > 0">Past Seminars</h2>
            <router-link :to="seminarLink(seminar)" v-for="(seminar, index) in loadedSeminars" :key="'past-seminar'+index">
                <past-seminar :data="seminar"></past-seminar>
            </router-link>

            <br>
            <div class="loadmore load-seminar" v-if="this.pastSeminarData.length > this.loaded">
                <button ref="buttonLoadMore" class="cta" @click="loadMore()"><span>Load More</span></button>
            </div>

        </basic-page>
    </div>
</template>
    
<script>
import { fetchNodes, fetchSingleNode } from '../libs/drupalClient'
import BasicPage from './BasicPage.vue'
import PastSeminar from '../components/PastSeminar.vue'
import UpcomingSeminar from '../components/UpcomingSeminar.vue'
import { detailsLink } from '../libs/utils'

export default {
    components: { 
        BasicPage, 
        PastSeminar,
        UpcomingSeminar
    },
    name: 'seminars',
    data: () => {
        return {
            currentPageId: "24f1b3a9-d43d-46dc-b61f-a524eb6e6c1f",
            pageData: {},
            allSeminars: [],
            loaded: 6,
            tagsArray: ['Ethics', 'Journal Clubs', 'SISSA DS^3'],
            filteringTags: [],
            research: '',
            bannerimg: null
        }
    },
    methods: {
        seminarLink(e) {
            return detailsLink('seminar', e)
        },
        scrollToBtn(){
            setTimeout(()=>{
                var element = this.$refs.buttonLoadMore;
                var top = element.offsetTop;

                window.scrollTo({
                    top:top,
                    behavior: 'smooth',
                });
            }, 200)
        },
        loadMore() {
            this.loaded += 4;
            this.scrollToBtn()
        },
        addFilter(event) {
            if(this.filteringTags.includes(event.target.value))
                this.filteringTags.splice(this.filteringTags.indexOf(event.target.value), 1)
            else
                this.filteringTags.push(event.target.value)
                //console.log(this.filteringTags)
        },
        checkActive(t) {
            return this.filteringTags.includes(t)
        },

    },
    computed: {
        loadedSeminars() {
            return this.pastSeminarData.slice(0,this.loaded)
        },
        pastSeminarData() {
            return this.filteredAllSeminars.filter(s => {
                return Date.parse(s.attributes.field_seminar_end_date) < Date.now()
            }).sort((a, b) => {
                return Date.parse(a.attributes.field_seminar_end_date) > Date.parse(b.attributes.field_seminar_end_date) ? -1 : 1
            })
        },
        upcomingSeminarData() {
            return this.filteredAllSeminars.filter(s => {
                return Date.parse(s.attributes.field_seminar_end_date) >= Date.now()
            }).sort((a, b) => {
                return Date.parse(a.attributes.field_seminar_end_date) > Date.parse(b.attributes.field_seminar_end_date) ? 1 : -1
            })
        },
        filteredAllSeminars() {
            var sems = []

            if(this.filteringTags.length > 0)
                sems = this.allSeminars.filter(s => {
                    if(s.field_ref_seminar_cat)
                        return this.filteringTags.includes(s.field_ref_seminar_cat.attributes.name)
                    else
                        return false
                })
            else
                sems = this.allSeminars

            return sems.filter(n => {
                return (
                    n.attributes.title.toLowerCase().includes(this.research.toLowerCase())
                    || n.attributes.field_seminar_description.processed.toLowerCase().includes(this.research.toLowerCase())
                )
            })
        }
    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top'] })
            .then(res => {
                this.pageData = res[0].attributes
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })
        fetchNodes('seminar', {include:['field_seminar_image', 'field_seminar_internal_speaker', 'field_ref_seminar_type', 'field_ref_seminar_cat', 'field_ref_seminar_tag']})
            .then(res => {
                this.allSeminars = res
                //console.log(this.allSeminars)

                //generates filter tags dynamically based on what appear in the Category field of the present Seminars
                /*
                this.tagsArray = []
                this.allSeminars.forEach(s => {
                    if(s.field_ref_seminar_cat)
                        if(!this.tagsArray.includes(s.field_ref_seminar_cat.attributes.name))
                            this.tagsArray.push(s.field_ref_seminar_cat.attributes.name)
                })
                */

                /*this.allSeminars.forEach(s => {
                    if(Date.parse(s.attributes.field_seminar_end_date) < Date.now())
                        this.pastSeminarData.push(s)
                    else this.upcomingSeminarData.push(s)
                })*/
            })
        }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";

html {
  scroll-behavior: smooth;
}

.seminars .container a {
    text-decoration: none;
}

.seminars .title-section {
  margin-bottom: 4rem;
}

.paragrafo a,
.paragrafo ::v-deep a, {
    color: #FDDB40;
}

.paragrafo ::v-deep span {
    em, i, strong {
        color: #C9CFD9;    
    }
    color: #C9CFD9;
}

.filtri {
    .tags-filter {
        .image-tag {
            background: transparent;
            color: #fff;
            border: rgba(255, 255, 255, 0.3) 1px solid;

            &:hover {
                background: $mainColor;
                border: $mainColor 1px solid;
                color: $secondaryColor;
            }

            &.active {
                background: #fff;
                color: $secondaryColor;
                border: #fff 1px solid;
            }
        }
    }

    .ricerca-testo input {
        background: transparent;
        border: rgba(255, 255, 255, 0.3) 1px solid;
        color: $mainColor;
        &::placeholder {
            color: #fff;
        }
    }
}

</style>