<template>
  <div class="seminar-wrapper past">
    <div class="date">
      <p class="day">{{ seminarDate.getDate() }}</p>
      <p class="month">{{ month }}</p>
      <p class="year">{{ seminarDate.getFullYear() }}</p>
    </div>
    <div class="content">
      <span class="tag">
        {{ this.data.field_ref_seminar_cat ? this.data.field_ref_seminar_cat.attributes.name : '' }}
        {{ this.data.field_ref_seminar_type && this.data.field_ref_seminar_cat ? ' - ' : ''}}
        {{ this.data.field_ref_seminar_type ? this.data.field_ref_seminar_type.attributes.name : ''}}
      </span>
      <p class="title">{{ this.data.attributes.title }}</p>
      <span class="speaker-tag" v-if="allSpeakers.length > 0">speaker</span>
      <div class="speaker" >
        <div v-for="(speaker, index) in allSpeakers" :key="index" >
          <router-link v-if="speaker.type=='internal'" :to="personLink(speaker)">
            <p class="speaker-name">{{speaker.name}}</p>
          </router-link>
          <p v-else class="speaker-name">{{speaker.name}}</p>
        </div>
      </div>
    </div>
    <div class="external">
      <a>
        <img src="main-logo.svg" alt="">
      </a>
    </div>
  </div>
</template>

<script>
import { convertMonth } from '../libs/utils'
import { detailsLink } from '../libs/utils'

export default {
  name: 'past-seminar',
  data: () => {
    return {

    }
  },
  props: {
    data: Object
  },
  methods: {
    personLink(p) {
      //console.log(p)
      var sp = {
        attributes: {
          field_first_name: p.name.split(' ')[0],
          field_last_name: p.name.split(' ')[1],
          drupal_internal__nid: p.nid
        }
      }
      return detailsLink('person', sp)
    }
  },
  computed: {
    allSpeakers() {
      var tot = []

      this.data.attributes.field_seminar_external_speaker.forEach(esp => {
        tot.push({ type: 'external', name: esp })
      });
      this.data.field_seminar_internal_speaker.forEach(isp => {
        tot.push({ type: 'internal', name: isp.attributes.field_first_name + ' ' + isp.attributes.field_last_name, nid: isp.attributes.drupal_internal__nid })
      })

      //console.log(tot)

      return tot.sort((a, b) =>  {
        return a.name.split(' ')[1] > b.name.split(' ')[1] ? 1 : -1
      })
    },
    seminarDate() {
      //console.log(Date.parse(this.data.attributes.field_seminar_end_date))
      return new Date(Date.parse(this.data.attributes.field_seminar_end_date))
    },
    month() {
      return convertMonth(this.seminarDate.getMonth())
    }
  },
  mounted() {
    //console.log(this.data)
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";


.seminar-wrapper.past {
  align-items: stretch;
  .date,
  .content {
    display: flex; display: -webkit-flex;
    flex-direction: column;
    //justify-content: center;
  }
}
.date {
    height: auto;
    width: 20%;
    @media (min-width: 992px) {
        padding: 1.5em;
    }
    position: relative;
    border-right: 1px rgba(255, 255, 255, 0.3) solid;
    border-bottom: 0px;


  .day {
    line-height: 1em;
    //margin-top: -.2em;
  }
  .month {
    font-size: 1.125rem;
    @media (min-width: 992px) {
      font-size: 1.4em;
    }
  }
  .year {
    font-size: .9em;
  }
}

.content {
    width: 65%;
    padding: 1em;
    @media (min-width: 992px) {
      padding: 1.5em 4rem; 
    }
    color: #fff;

    .speaker {
      display: flex; display: -webkit-flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .speaker-tag {
      text-transform: uppercase;
      color: #999;
      font-size: 0.875rem;
      display: block;
      font-weight: 700;
      margin: 0;
    }
    .speaker-name {
      margin-right: 20px;
      font-size: 0.875rem;
      font-size: 1.15rem;
    }
}

.external {
    width: 15%;
    padding: 1em;
    display: flex; display: -webkit-flex;
    align-items: center;
    display: none;
    
    @media (min-width: 992px) {
        padding: 1.5em;
    }

    a {
        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
}


@media (max-width: 992px) {
  .seminar-wrapper.past {
    flex-direction: row;
    .date::after {
      display: none;
    }
  }
}
@media (max-width: 768px) {
    .seminar-wrapper.past {
        flex-direction: column;
        &> div {
          width: 100%;
        }
        .content {
          padding: 2em 20px 2em 20px;
        }
        .date {
          border: 0;
          border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
        }
        .external {
          display: none;
            a {
                width: 100%;
                img {
                    max-height: 100px;
                    object-fit: contain;
                }
            }
        }
    }
}
</style>